<template>
  <div class="mobile-header">
        <a class="logo" href="/">
            <img src="@/assets/images/logo.png" alt="logo pos">
        </a>
        <div class="d-flex align-items-center gap-4">
            <div class="toggle-btn fs-3" id="btn-mobile">
                <i class='bx bxs-sun btn-icon'></i>
            </div>
            <div class="menu-btn fa-solid fa-bars" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
        </div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header justify-content-between">
                <a class="logo" href="/">
                    <img src="@/assets/images/logo.png" alt="logo pos">
                </a>
                <button type="button" class="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i class="fa-solid fa-xmark fs-1"></i>
                </button>
            </div>
            <div class="offcanvas-body">
                <li><a id="link" class="link active" href="/#home">Home</a></li> 
                <li><router-link id="link" class="link" to="/about">About</router-link></li>
                <li><a id="link" class="link" href="/#products">Products</a></li>
                <li><a id="link" class="link" href="/#activities">Activities</a></li>
                <li><a id="link" class="link" href="#contact">Contact</a></li>
            </div>
        </div>
    </div> 
</template>

<script>
export default {
    name: "MobileHeader",
    mounted () {
        const mobileHeader = document.querySelector(".mobile-header");
window.addEventListener("scroll", function() {
    mobileHeader.classList.toggle ("sticky",window.scrollY > 130);
});
    },
}
</script>

<style>

</style>