<template>
  <div class="slider" id="home">
    <div class="list" ref="sliderList">
      <div class="item" v-for="(slide, index) in slides" :key="index">
        <img class="blurred-image" :src="slide.image" :alt="slide.type">
        <div class="content">
          <div class="title">DECAFORT</div>
          <div class="type">{{ slide.type }}</div>
          <div class="description">{{ slide.description }}</div>
          <div class="button">
            <router-link class="btn" to="/about">SEE MORE</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="thumbnail" ref="thumbnail">
      <div class="item overflow-hidden" v-for="(thumb, index) in slides" :key="'thumb-' + index">
        <img class="blurred-image" :src="thumb.image" :alt="thumb.type">
      </div>
    </div>
    <div class="nextPrevArrows">
      <button class="prev">
        <i class="fa-solid fa-angle-left"></i>
      </button>
      <button class="next">
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HomeComponent',
  data() {
    return {
      slides: [
        {
          image: require('@/assets/images/img2.webp'),
          type: 'LIMITED',
          description: 'Your trusted partner in the supply and distribution of agricultural products across Nigeria and beyond',
        },
        {
          image: require('@/assets/images/img1.webp'),
          type: 'LIMITED',
          description: 'Your trusted partner in the supply and distribution of agricultural products across Nigeria and beyond',
        },
        {
          image: require('@/assets/images/img4.webp'),
          type: 'LIMITED',
          description: 'Your trusted partner in the supply and distribution of agricultural products across Nigeria and beyond',
        },
        {
          image: require('@/assets/images/img3.webp'),
          type: 'LIMITED',
          description: 'Your trusted partner in the supply and distribution of agricultural products across Nigeria and beyond',
        },
      ],
    };
  },
  mounted () {
    let nextBtn = document.querySelector('.next')
let prevBtn = document.querySelector('.prev')

let slider = document.querySelector('.slider')
let sliderList = slider.querySelector('.slider .list')
let thumbnail = document.querySelector('.slider .thumbnail')
let thumbnailItems = thumbnail.querySelectorAll('.item')

thumbnail.appendChild(thumbnailItems[0])

// Function for next button 
nextBtn.onclick = function() {
    moveSlider('next')
}


// Function for prev button 
prevBtn.onclick = function() {
    moveSlider('prev')
}


function moveSlider(direction) {
    let sliderItems = sliderList.querySelectorAll('.item')
    let thumbnailItems = document.querySelectorAll('.thumbnail .item')
    
    if(direction === 'next'){
        sliderList.appendChild(sliderItems[0])
        thumbnail.appendChild(thumbnailItems[0])
        slider.classList.add('next')
    } else {
        sliderList.prepend(sliderItems[sliderItems.length - 1])
        thumbnail.prepend(thumbnailItems[thumbnailItems.length - 1])
        slider.classList.add('prev')
    }


    slider.addEventListener('animationend', function() {
        if(direction === 'next'){
            slider.classList.remove('next')
        } else {
            slider.classList.remove('prev')
        }
    }, {once: true}) // Remove the event listener after it's triggered once
}
  },
}
</script>

<style>

</style>