<template>
  <div class="footer section position-relative py-0" id="contact">
    <div class="background-shapes">
        <div class="shape-group z-n1">
            <div class="shape bg-shape1"></div>
            <div class="shape bg-shape2"></div>
        </div>
    </div>
    <div class="content d-flex flex-column align-items-center justify-content-center">
        <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
        </div>
        <p class="fw-semibold">Potiskum Potiskum 02100 Yobe, Nigeria</p>
        <p class="fw-semibold"><a href="mailto:decafortlimited@gmail.com">decafortlimited@gmail.com</a></p>
        <p class="fw-semibold">
            <a href="tel:+2349123524671">+2349123524671</a>  |
            <a href="tel:+2347066626559">+2347066626559</a>
        </p>
        <hr>
        <p class="text-center fw-semibold"> &copy;2024 by Octadot | All rights reserved. </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
hr{
    border: 1px solid rgba($color: #8DD7F8, $alpha: .5);
    width: 100%;
}

</style>