<template>
    <div class="row d-md-flex">
        <div class="col-md-6 title">
            <h1 class="display-1 fw-bold">{{ description.title_1 }}</h1>
            <h1 class="display-1 fw-bold ms-5">{{ description.title_2 }}</h1>
        </div>
        <div class="col-md-6 text-content align-content-center">
            <p class="fw-semibold">{{ description.content }}</p>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "DescriptionComponent",
    props:{
      description:{
        type: Array
      }
    }
}
</script>

<style>

</style>