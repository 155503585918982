var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-header"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"offcanvas offcanvas-end",attrs:{"tabindex":"-1","id":"offcanvasRight","aria-labelledby":"offcanvasRightLabel"}},[_vm._m(2),_c('div',{staticClass:"offcanvas-body"},[_vm._m(3),_c('li',[_c('router-link',{staticClass:"link",attrs:{"id":"link","to":"/about"}},[_vm._v("About")])],1),_vm._m(4),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo pos"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center gap-4"},[_c('div',{staticClass:"toggle-btn fs-3",attrs:{"id":"btn-mobile"}},[_c('i',{staticClass:"bx bxs-sun btn-icon"})]),_c('div',{staticClass:"menu-btn fa-solid fa-bars",attrs:{"type":"button","data-bs-toggle":"offcanvas","data-bs-target":"#offcanvasRight","aria-controls":"offcanvasRight"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offcanvas-header justify-content-between"},[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo pos"}})]),_c('button',{staticClass:"close-btn",attrs:{"type":"button","data-bs-dismiss":"offcanvas","aria-label":"Close"}},[_c('i',{staticClass:"fa-solid fa-xmark fs-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"link active",attrs:{"id":"link","href":"/#home"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"link",attrs:{"id":"link","href":"/#products"}},[_vm._v("Products")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"link",attrs:{"id":"link","href":"/#activities"}},[_vm._v("Activities")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"link",attrs:{"id":"link","href":"#contact"}},[_vm._v("Contact")])])
}]

export { render, staticRenderFns }