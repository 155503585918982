<template>
  <div>
    <home/>
    <!-- about section start -->
    <div class="about section">
      <h5 class="text-center">About Us</h5>
      <h1 class="display-1 text-center fw-bolder"> DecaFort Limited</h1>
      <p class="text-center fw-semibold">
        DecaFort Limited is a Nigerian registered company that specialises in supply and distribution of agricultural product across Nigeria and beyond. Over the years, we have satisfied our clients by supplying tonnes of Agro-products such as rice, sesame, ginger, maize, cowpea, soya beans, sorghum, roselle (zobo) as well as livestock. 
        <br>
        Our clients ranging from Agro-allied industries, farmers, NGOs, Governmental institutions and individuals enjoy our dedication to excellence and sustainability which drives every aspect of our business.
      </p>
      <div class="row g-3">
        <div class="col-sm-12 col-md-6">
          <div class="overflow-hidden">
            <img src="@/assets/images/about-1.webp" class="blurred-image w-100 h-100" alt="about decafort">
          </div>
        </div>
        <div class="col-sm-12 col-md-6 overflow-hidden">
          <div class="overflow-hidden">
            <img src="@/assets/images/about-2.webp" class="blurred-image w-100 h-100" alt="about decafort">
          </div>
        </div>
      </div>
    </div>
    <!-- about section end -->
    <!-- mission section start -->
    <div class="mission section position-relative">
      <img src="@/assets/images/bg-mission-1.webp" alt="" class="position-absolute start-0 bottom-0 z-n1">
      <img src="@/assets/images/bg-mission-2.webp" alt="" class="position-absolute end-0 bottom-0 z-n1  d-none d-sm-block ">
      <Description :description="mission"/>
    </div>
    <!-- mission section end -->
    <!-- values section start -->
    <div class="values section position-relative">
      <img src="@/assets/images/values-bg.webp" class="position-absolute start-0 bottom-0 w-100" alt="">
      <div class="row g-3">
        <div class="col-sm-6 col-md-4 col-lg-3"
        v-for="value in values" :key="value.id">
        <div class="card-value h-100 p-3 d-flex flex-column justify-content-around">
          <div class="top d-flex justify-content-between text-secondary">
            <h2>{{ value.id }}</h2>
            <h2><i :class="value.icon"></i></h2>
          </div>
          <div class="bottom">
            <h6 class="text-secondary-dark fw-bold">{{ value.title }}</h6>
            <p class="lh-sm">{{ value.description }}</p>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- values section end -->
    <!-- vision section start -->
    <div class="vision section">
      <div class="background-shapes position">
        <div class="shape-group">
            <div class="shape bg-shape1"></div>
            <div class="shape bg-shape2"></div>
        </div>
    </div>
      <Description :description="vision"/>
    </div>
    <!-- vision section end -->
  </div>
</template>

<script>
import Description from '@/components/partial/Description.vue';
import home from '@/components/partial/Home.vue';

export default {
  components: {
    Description,home
  },
  data() {
    return {
      mission: {
        title_1: "Our",
        title_2: "Mission",
        content: "Our mission is to supply high quality agricultural products (crops and livestock) to our partners, guaranteeing cross regional products within the country. We strive to positively impact the agricultural industries, including business owners and individuals contributing to the development and well-being of our communities"
      },
      vision: {
        title_1: "Our",
        title_2: "Vision",
        content: "Our vision is to address the livelihood challenges capitalizing on Automated greenhouse farming and exploring international markets through export and import."
      },
      values:[
        {
          id: "01",
          icon: "fas fa-seedling",
          title: "Quality",
          description: "We are dedicated to producing high-quality crops that directly respond to our clients' needs"
        },
        {
          id: "02",
          icon: "fas fa-leaf",
          title: "Sustainability",
          description: "Sustainability is paramount in our agricultural practices."
        },
        {
          id: "03",
          icon: "fas fa-lightbulb",
          title: "Innovation and Market Creation",
          description: "We continuously explore innovative and modern farming techniques and stay up-to-date with the latest and cross-cultural design trends and technologies to create new markets"
        },
        {
          id: "04",
          icon: "fas fa-hands-helping",
          title: "Community",
          description: "We believe in giving back to the communities we serve and actively participate in initiatives that promote social and economic development through employment and skill acquisition for the host communities"
        },
      ]
    }
  },
}
</script>

<style>
.description{
  flex-direction: row-reverse;
}
</style>