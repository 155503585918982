<template>
  <div id="app">
    <div class="main-header">
        <DefaultHeader/>
        <MobileHeader/>
    </div>
    <div class="Scroll">
        <button class="btnScroll">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
        </button>
    </div>
    <router-view/>
    <DefaultFooter/>
  </div>
</template>

<script>
import DefaultHeader from './components/partial/headers/DefaultHeader.vue';
import MobileHeader from './components/partial/headers/MobileHeader.vue';
import DefaultFooter from './components/partial/DefaultFooter.vue';
export default {
    components: {
        MobileHeader,DefaultHeader,DefaultFooter
    },
    mounted () {
        document.addEventListener("DOMContentLoaded", function() {
            const images = document.querySelectorAll('.blurred-image');

            images.forEach(image => {
                image.onload = function() {
                    image.style.filter = "blur(0)";
                };

                if (image.complete) {
                    image.onload();
                }
            });
        });
        //dark/light mode
        document.addEventListener("DOMContentLoaded", function() {
            let currentTheme = localStorage.getItem('theme');
            
            if (!currentTheme) {
                currentTheme = 'dark-theme';
                localStorage.setItem('theme', 'dark-theme');
            }

            if (currentTheme === 'dark-theme') {
                document.body.classList.add('dark-theme');
                document.querySelectorAll('.btn-icon').forEach(btnIcon => {
                    btnIcon.classList.replace('bxs-moon', 'bxs-sun');
                });
            } else {
                document.body.classList.add('light-theme');
                document.querySelectorAll('.btn-icon').forEach(btnIcon => {
                    btnIcon.classList.replace('bxs-sun', 'bxs-moon');
                });
            }
        });

        function toggleTheme() {
            if (document.body.classList.contains('dark-theme')) {
                document.body.classList.replace('dark-theme', 'light-theme');
                document.querySelectorAll('.btn-icon').forEach(btnIcon => {
                    btnIcon.classList.replace("bxs-sun", "bxs-moon");
                });
                localStorage.setItem('theme', 'light-theme');
            } else {
                document.body.classList.replace('light-theme', 'dark-theme');
                document.querySelectorAll('.btn-icon').forEach(btnIcon => {
                    btnIcon.classList.replace("bxs-moon", "bxs-sun");
                });
                localStorage.setItem('theme', 'dark-theme');
            }
        }

        document.getElementById("btn").onclick = toggleTheme;
        document.getElementById("btn-mobile").onclick = toggleTheme;
        //btn scroll
        const btnScroll = document.querySelector(".btnScroll");
        btnScroll.addEventListener("click", function () {
        window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            }); 
        });
    },
}
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  .blurred-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
    transition: filter 1s ease;
  }


.main-header{
    position: relative;
    z-index: 100;
}

</style>
