<template>
  <div>
    <home/>
      <!-- about section start -->
      <div class="about section overflow-hidden">
        <description :description="about">
          <router-link class="h5" to="/about">learn more <i class="fa-solid fa-angles-right fa-fade"></i></router-link>
        </description>
        <img src="@/assets/images/about.webp" class="w-100 pt-5 blurred-image" alt="about decafort">
      </div>
      <!-- about section end -->
      <!-- products section start -->
      <div class="products section" id="products">
        <description :description="products"/>
        <CardProducts/>
      </div>
      <!-- products section end -->
      <!-- livestock section start -->
      <div class="livestock section position-relative">
        <description :description="livestock"/>
        <img class="position-absolute start-0 bottom-0 w-25 h-50 object-fit-contain z-n1" src="@/assets/images/cow.webp" alt="">
        <img class="position-absolute end-0 bottom-0 w-25 h-50 object-fit-contain z-n1" src="@/assets/images/sheep.webp" alt="">
      </div>
      <!-- livestock section end -->
      <!-- activities section start -->
      <div class="activities section" id="activities">
        
        <h5 class="text-center">Our activities</h5>
        <h1 class="display-3 text-center fw-bolder">Agricultural Production and Distribution</h1>
        <div class="row pt-5 position-relative overflow-hidden">
          <img class="blurred-image" src="@/assets/images/activities-bg.webp" style="min-height: 400px; max-height:650px;">
          <div class="text-overlay">
            <p class="text-center text-white">We engage in the cultivation of various farm products using modern and sustainable farming techniques. Additionally, we distribute and market agricultural products and inputs to reach a wide range of customers. This includes agro-allied products such as fertilizers, chemicals, improved seeds, and machines. We are committed to promoting food production and the marketing of agricultural products.</p>
          </div>
        </div>
      </div>
      <!-- activities section end -->
  </div>
</template>

<script>
import description from '@/components/partial/Description.vue';
import CardProducts from '@/components/elements/CardProducts.vue';
import home from '@/components/partial/Home.vue';

export default {
    name: "HomeView",
    components: {
      description, CardProducts,home
    },
    data() {
      return {
        about:{
          title_1: "About",
          title_2: "DecaFort",
          content: "DecaFort Limited is a Nigerian registered company that specialises in supply and distribution of agricultural product across Nigeria and beyond. Over the years, we have satisfied our clients by supplying tonnes of Agro-products such as rice, sesame, ginger, maize, cowpea, soya beans, sorghum, roselle (zobo) as well as livestock. "
        },
        products:{
          title_1: "Our",
          title_2: "Products",
          content: "DecaFort Limited is a Nigerian registered company that specialises in supply and distribution of agricultural product acros"
        },
        livestock:{
          title_1: "Livestock",
          title_2: "Production",
          content: "Decafort Ltd excels in sustainable livestock farming, specializing in sheep and cows. We employ advanced techniques in breeding, nutrition, and veterinary care, aiming to produce high-quality meat and dairy products for the market."
        }
      }
    },
    
    
}
</script>


<style>

  
</style>
