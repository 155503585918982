<template>
     <div class="row g-5 pt-5 position-relative">
        <img class="image-1" src="@/assets/images/bg-product-1.webp" alt="">
        <img class="image-2" src="@/assets/images/bg-product-2.webp" alt="">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 position-relative"
        v-for="product in products" :key="product.id">
            <div class="card-product d-flex flex-column align-items-center justify-content-evenly">
                <h3 class="text-primary">{{ product.title }}</h3>
                <img class="blurred-image" :src="product.image" :alt="product.title">
            </div>
            <img class="img-bg" :src="product.image" :alt="product.title">
        </div>
       
    </div>
</template>

<script>
export default {
    name: "CardProducts",
    data() {
        return {
            products:[
                {
                    title: "Rice",
                    image: require('@/assets/images/rice.webp'),
                },
                {
                    title: "Sesame",
                    image: require('@/assets/images/sesame.webp'),
                },
                {
                    title: "Ginger",
                    image: require('@/assets/images/ginger.webp'),
                },
                {
                    title: "Maize",
                    image: require('@/assets/images/maize.webp'),
                },
                {
                    title: "Cowpea",
                    image: require('@/assets/images/cowpea.webp'),
                },
                {
                    title: "Soya Beans",
                    image: require('@/assets/images/soy-beans.webp'),
                },
                {
                    title: "Sorghum",
                    image: require('@/assets/images/Sorghum.webp'),
                },
                {
                    title: "Roselle",
                    image: require('@/assets/images/roselle.webp'),
                },
            ]
        }
    },
}
</script>

<style>

</style>