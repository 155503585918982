import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/custom.scss';
import './assets/scss/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
