<template>
  <div class="header">
        <a class="logo" href="/">
            <img src="@/assets/images/logo.png" alt="logo decafort">
        </a>
    <div class="navbar">
        <li><a id="link" class="link" href="/#home">Home</a></li> 
        <li><router-link id="link" class="link" to="/about">About</router-link></li>
        <li><a id="link" class="link" href="/#products">Products</a></li>
        <li><a id="link" class="link" href="/#activities">Activities</a></li>
    </div>
    <div class="d-flex align-items-center gap-4">   
        <div class="toggle-btn fs-3" id="btn">
            <i class='bx bxs-sun btn-icon'></i>
        </div>
        <a id="link" class="btn"  href="#contact">Contact</a>
    </div>
</div> 
</template>

<script>
export default {
    name: "DefaultHeader",
    mounted () {
        const header = document.querySelector(".header");
window.addEventListener("scroll", function() {
    header.classList.toggle ("sticky",window.scrollY > 130);
});
    },

}
</script>

<style>

</style>